.common-select {
  .Select-control {
    border: 1px solid #23282c !important;
    background: #515b65 !important;
    border-radius: 4px !important;
    color: #e4e7ea !important;
  }
}
.common-select.input-required {
  .Select-control {
    border: 1px solid #e55353 !important;
  }
}

.Select.is-disabled .Select-control {
  background-color: #3a4149 !important;
  opacity: 1;
}

.Select-placeholder {
  text-align: left !important;
  padding-left: 7px !important;
}

.Select.Select--single .Select-input {
  width: 100% !important;
  text-align: left;
}

.Select-menu-outer {
  background: #515b65 !important;
  border: none !important;
  min-height: 7rem !important;
  color: #e4e7ea !important;
  margin-top: -5px !important;
  z-index: 99999 !important;
  .Select-menu {
    .Select-option {
      background: #515b65 !important;
      color: #e4e7ea !important;
      text-align: left !important;
    }
    .Select-option:hover {
      background: #5ab1d0 !important;
      color: #000 !important;
    }
  }
}

.supplier-select {
  .Select-menu-outer {
    max-height: 300px !important;
  }
  .Select-menu {
    max-height: 298px !important;
  }
}

.arranger-select .Select-menu-outer {
  width: 150% !important;
}

.Select--multi.is-disabled .Select-value {
  background-color: #ebf5ff !important;
  background-color: rgba(0, 126, 255, 0.08) !important;
  border: 1px solid #c2e0ff !important;
  border: 1px solid rgba(0, 126, 255, 0.24) !important;
  color: #007eff !important;
}

.Select-control .Select-input:focus {
  background: #515b65 !important;
}

.Select-input input {
  color: #e4e7ea !important;
}

.Select-value-label {
  color: #e4e7ea !important;
}

.common-normal-select {
  border: 1px solid #23282c !important;
  background: #515b65 !important;
  border-radius: 4px !important;
  color: #e4e7ea !important;
  width: 40%;
  height: 35px;
  padding: 5px;
  margin-top: -5px;
  outline: none;
}
