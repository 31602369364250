.container-fluid {
  padding: 0px !important;
}

.card {
  border-top: 1px solid #23282c !important;
  border-right: 0px !important;
  border-bottom: 0px !important;
  border-left: 0px !important;
  border-radius: 0px !important;
}

.top-header,
.breadcrumb {
  border-bottom: 0px !important;
}

.text-danger {
  cursor: pointer;
  font-weight: 600;
}

.no-padding {
  padding: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.required {
  color: #e55353;
}

.create-btn,
.submit-btn {
  float: right;
}

.paymentModalBox {
  min-width: 700px;
}

.btn-unlink {
  color: #fff;
}

.error-txt {
  color: #e55353;
  font-size: 13px;
  margin-left: 9px;
}

.highlight-item,
.highlight-item span {
  color: #fdf4c7 !important;
}

.commonTable .btn-link {
  padding-left: 0px;
}

.link {
  color: #20a8d8;
  cursor: pointer;
}

.paymentModalBox.productModal {
  min-width: 700px;
  max-height: 600px;
}
.item-modal {
  max-width: 420px;
}

.product-modal-show {
  margin-bottom: 20px;
  margin-top: -4px;
  float: right;
  margin-right: 20px;
  cursor: pointer;
  color: #20a8d8;
}
.product-modal-show:hover {
  text-decoration: underline;
}
.form-control:disabled,
.form-control[readonly],
.btn-primary.disabled,
.btn-primary:disabled {
  pointer-events: none;
}
.date-filter {
  width: 130px;
}
.form {
  display: block;
  width: 50%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #e4e7ea;
  background-color: #515b65;
  background-clip: padding-box;
  border: 1px solid #23282c;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.transfer-date {
  display: block;
  width: 300px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #e4e7ea;
  background-color: #515b65;
  background-clip: padding-box;
  border: 1px solid #23282c;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-required {
  border-color: #e55353;
  .Select-control {
    border-color: #e55353 !important;
  }
}

.close {
  color: #fff;
}
.log-record {
  .log-line {
    line-height: 25px;
  }
  .green-color {
    color: #1de01d;
  }
  .red-color {
    color: #e76b63;
  }
}

.rc-time-picker-panel-inner {
  height: 340px !important;
  .rc-time-picker-panel-select {
    max-height: none !important;
  }
}
.timepicker-disabled {
  background: #3a4149 !important;
  .rc-time-picker-input {
    background: #3a4149 !important;
  }
}
.rc-time-picker-input {
  padding: 0px !important;
  height: auto !important;
  line-height: 0px !important;
  background: #515b65 !important;
  color: #fff !important;
  border: 0px !important;
  outline: none !important;
}
.rc-time-picker-panel-combobox li {
  color: #000 !important;
}
.checked.hotel-mobile-switch {
  .react-switch-bg {
    background-color: #20a8d8 !important;
  }
}
.unchecked.hotel-mobile-switch {
  .react-switch-bg {
    background-color: #ccc !important;
  }
}
.editorClassName {
  background-color: #fff !important;
  color: #000 !important;
}
.rdw-editor-toolbar {
  margin-bottom: 0px !important;
}
.rdw-editor-main {
  min-height: 250px;
}
.promo-title .rdw-editor-main {
  min-height: 150px !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.refund-modal .row {
  padding-bottom: 10px;
}
.export-btn {
  position: absolute;
  top: 75px;
  right: 30px;
  z-index: 100;
}
.btn-filter-apply {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1;
}
.NFilterRow.disableRow {
  pointer-events: none;
}
.card-header.transactionSync {
  height: 80px;
  .dateRangePicker {
    top: 95px !important;
  }
}

.react-datepicker-popper {
  z-index: 2 !important;
}
.exportDatePicker {
  position: absolute;
  top: 10px;
  left: 233px;
  .DateRangePicker_picker {
    z-index: 2;
  }
}
.export-reservation-btn {
  position: absolute;
  top: 1px;
  left: 300px;
}
.hide {
  display: none;
}
.validDateContainer {
  padding-left: 20px;
  padding-top: 20px;
}

/* Start of multiselect */
.multiSelectContainer {
  .searchWrapper {
    border: 1px solid #23282c !important;
    background: #515b65 !important;
    border-radius: 4px !important;
  }
  .optionListContainer {
    border: 1px solid #23282c !important;
    background: #515b65 !important;
    border-radius: 4px !important;
    color: #e4e7ea !important;
  }
  input {
    color: #fff!important;
  }
  ul {
    border: 1px solid #23282c !important;
  }
  li {
    color: #e4e7ea !important;
  }
  li:hover {
    background: #5ab1d0 !important;
    color: #000 !important;
  }
  .highlightOption {
    background: #515b65 !important;
    color: #e4e7ea !important;
  }
}
/* End of multiselect */
