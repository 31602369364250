.react-phone-div .input-group-prepend {
  width: 100%;
  .phone {
    width: 100% !important;
    .react-tel-input {
      width: 100% !important;
      max-width: 100% !important;
      .form-control {
        width: 100% !important;
        background-color: #515b65 !important;
        border: 1px solid #23282c !important;
      }
      .flag-dropdown {
        background-color: #515b65 !important;
        border-top: 0px !important;
        border-right: 1px solid #23282c !important;
        border-bottom: 0px !important;
        border-left: 0px !important;
        margin-top: 2px;
        margin-left: 2px;
        .arrow {
          border-top: 4px solid #fff !important;
        }
        .country-list {
          background-color: #515b65 !important;
          .dial-code {
            color: #fff !important;
          }
          .highlight {
            .country-name {
              color: #515b65 !important;
            }
            .dial-code {
              color: #515b65 !important;
            }
          }
          li:hover {
            .country-name {
              color: #515b65 !important;
            }
            .dial-code {
              color: #515b65 !important;
            }
          }
        }
      }
      .flag-dropdown:hover {
        .selected-flag {
          background-color: #515b65 !important;
        }
      }
      .flag-dropdown:focus {
        .selected-flag {
          background-color: #515b65 !important;
        }
      }
    }
  }
}
