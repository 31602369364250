.popover {
  max-width: 600px !important;
}

.people-popover {
  .popover-wrapper {
    min-width: 300px !important;
  }
  .arrow {
    left: 10px !important;
  }
  .popover-body {
    min-height: 160px;
  }
}

.transaction-popover {
  .popover-wrapper {
    min-width: 370px !important;
  }
  .arrow {
    left: 10px !important;
  }
  .popover-body {
    min-height: 115px;
  }
  li {
    list-style: none;
    line-height: 25px;
  }
  li:hover {
    color: rgb(32, 168, 216);
    cursor: pointer;
  }
}

.rexchangerate-popover {
  .popover-wrapper {
    min-width: 150px !important;
  }
  li {
    list-style: none;
    line-height: 25px;
  }
  li:hover {
    color: rgb(32, 168, 216);
    cursor: pointer;
  }
}

.warning-popover {
  .btn-primary {
    float: right;
  }
  .message {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 12px;
  }
}

.exchange-rate-modal {
  .remove-btn {
    float: right;
    margin-right: 20px;
  }
}

.confirmation-modal {
  p {
    font-size: 15px;
  }
  .btn {
    float: right;
  }
  .btn-primary {
    margin-left: 10px;
  }
}
.refundtype-modal {
  margin: 6.75rem auto !important;
  p {
    font-size: 15px;
  }
  .btn {
    width: 350px;
    text-align: center;
    margin: auto;
    display: block;
    float:none !important;
  }
  .row{
    padding-top: 20px;
    text-align: center;
  }
  h4,p{
    text-align: center;
  }

}
.refund-detail-modal{
  max-width:600px !important;
  .row{ text-align: left !important;}
  .infobox{
    margin:0px 30px;
  }
  .btn-full-refund{
    width: 100px !important;
    margin-left: 30px;
    float:right !important;
  }
  .item{
    position: absolute;
    right: -10px;
    bottom: 6px;
    cursor: pointer;
  }
}
.refund-normal-modal{
  .row{
    padding-top: 20px;
    text-align: center;
  }
  h4{
    text-align: left !important;
  }
  max-width:1100px !important;
  .row{ text-align: left !important;}
  .infobox{
    margin:0px;
  }
  .btn-full-refund{
    width: 100px !important;
    margin-left: 30px;
    float:right !important;
  }
  .item{
    position: absolute;
    right: -10px;
    bottom: 6px;
    cursor: pointer;
  }
  .normal-refund-box{
    padding-top:0px !important;
  }
}
